exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-find-us-js": () => import("./../../../src/pages/findUs.js" /* webpackChunkName: "component---src-pages-find-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-platform-casambi-js": () => import("./../../../src/pages/platform/casambi.js" /* webpackChunkName: "component---src-pages-platform-casambi-js" */),
  "component---src-pages-platform-emergency-js": () => import("./../../../src/pages/platform/emergency.js" /* webpackChunkName: "component---src-pages-platform-emergency-js" */),
  "component---src-pages-platform-sensors-js": () => import("./../../../src/pages/platform/sensors.js" /* webpackChunkName: "component---src-pages-platform-sensors-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-pgw-002-js": () => import("./../../../src/pages/products/pgw-002.js" /* webpackChunkName: "component---src-pages-products-pgw-002-js" */),
  "component---src-pages-promo-pf-may-24-js": () => import("./../../../src/pages/promo/pf-may-24.js" /* webpackChunkName: "component---src-pages-promo-pf-may-24-js" */)
}

